/**
 * Privacy Policy page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Herotype4 from "../components/herotype4"
import Layout from "../components/layout"
import Seo from "../components/seo"

/* Page function declaration */
const ProvidersGuideToCuresAct = ({data}) => {

  //Const data
  const dataPage = data.wpPage

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
      />

      {/* Hero section */}
      <section className="section-hero-static top-section">
        <Herotype4 h1title={dataPage.title}/>
      </section>

      {/*Static page details section */}
      <section className="section-details-static">
        <div className="wrap-maincontent-static">
          <div className="maincontent-static post-content" dangerouslySetInnerHTML={{ __html:dataPage.content}}  />
        </div>
      </section>
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "providers-guide-to-cures-act"}) {
      title
      content
      seo {
        title
        metaDesc
      }
    }
  }
`
/* Export page informations */
export default ProvidersGuideToCuresAct
